import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import PageStockCheck from 'Pages/stock-check';

const StockCheck = ({ match }) => (
	<div className="dashboard-wrapper">
		<Switch>
			<Route exact path={`${match.url}`} component={ PageStockCheck } />
			<Redirect to="/error" />
		</Switch>
	</div>
);
export default StockCheck;